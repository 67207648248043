import { UIAction } from 'actions'
import { getType } from 'typesafe-actions'
import {
  clearDeviceIdAction,
  setBadgeIdAction,
  setDeviceIdAction,
} from '../actions/ui'

export type UIState = Readonly<{
  mapView: {
    selectedBadgeId: string
    selectedDeviceId: string
  }
}>

const initialState = {
  mapView: {
    selectedBadgeId: '',
    selectedDeviceId: '',
  },
}

const uiReducer = (
  state: UIState = initialState,
  action: UIAction
): UIState => {
  switch (action.type) {
    case getType(setBadgeIdAction):
      return {
        ...state,
        mapView: {
          ...state.mapView,
          selectedBadgeId: action.payload,
        },
      }

    case getType(setDeviceIdAction):
      return {
        ...state,
        mapView: {
          ...state.mapView,
          selectedDeviceId: action.payload,
        },
      }

    case getType(clearDeviceIdAction):
      return {
        ...state,
        mapView: {
          ...state.mapView,
          selectedDeviceId: '',
        },
      }

    default:
      return state
  }
}

export default uiReducer
