import { ColumnDef } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import {
  getAgentType,
  getBatteryStatus,
  getSignalType,
} from '../../helpers/battery'
import { useGlobalSearch } from '../../hooks'
import { Badge, FetchingStatus } from '../../models'
import { ReactTable } from '../Tables'
import { renderUTCDate } from '../../helpers/reactTable'

interface Props {
  badges: Badge[]
  badgeStatus: FetchingStatus | undefined
}

interface BadgeRow extends Badge {
  batteryStatus: string
  formattedSignalType: string
  formattedAgentType: string
}

const BadgeManagementList: React.FC<Props> = (props: Props) => {
  const { badges, badgeStatus } = props

  const badgeRows: BadgeRow[] = useMemo((): BadgeRow[] => {
    return badges.map((badge) => {
      return {
        ...badge,
        batteryStatus: getBatteryStatus(badge.signalTypes, badge.lowBattery),
        formattedSignalType: getSignalType(badge.signalTypes),
        formattedAgentType: getAgentType(badge.agentType),
      }
    })
  }, [badges])

  const columns = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columnDefinitions: ColumnDef<BadgeRow, any>[] = [
      {
        accessorKey: 'formattedSignalType',
        header: 'RTLS Signal Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'badgeId',
        header: 'RTLS ID',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'formattedAgentType',
        header: 'RTLS Use Type',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'assignment',
        header: 'Assigned To',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'createdDate',
        cell: (cell) => renderUTCDate(cell.getValue(), 'MM/DD/YYYY'),
        header: 'Created Date',
        sortingFn: 'rtlsDateTime',
      },
    ]
    return columnDefinitions
  }, [])

  const tableData = useGlobalSearch(badgeRows, ['id']) ?? []

  return (
    <>
      <ReactTable
        tableToolbarOptions={{
          title: 'Badge Administration',
        }}
        tableOptions={{
          data: tableData,
          columns: columns,
          showLoading: badgeStatus === FetchingStatus.Request,
        }}
      />
    </>
  )
}

export default BadgeManagementList
