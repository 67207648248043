import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCloudReportingViewsAction } from '../actions/cloudReporting'
import { FetchingStatus } from '../models'
import {
  CloudReportingState,
  FetchCloudReportingErrorTypes,
} from '../models/cloudReporting'
import { CloudReportingView } from '../models/cloudReportingViews'

export interface FetchCloudReportingViewsResult {
  status: FetchingStatus | undefined
  views: CloudReportingView[] | undefined
  errorType: FetchCloudReportingErrorTypes
}

const useFetchCloudReportingViews = (): FetchCloudReportingViewsResult => {
  const dispatch = useDispatch()

  const cloudReporting = useSelector(
    ({ cloudReporting }: { cloudReporting: CloudReportingState }) =>
      cloudReporting
  )

  useEffect(() => {
    dispatch(getCloudReportingViewsAction.request())
  }, [dispatch])

  const errorType = !cloudReporting.error
    ? FetchCloudReportingErrorTypes.None
    : cloudReporting.error.status === 403
    ? FetchCloudReportingErrorTypes.Forbidden
    : FetchCloudReportingErrorTypes.Unknown

  return {
    status: cloudReporting.status,
    views: cloudReporting.data.views?.views,
    errorType,
  }
}

export { useFetchCloudReportingViews }
