import React from 'react'
import { PageGroup, PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { getAssociatedRoute } from '@midmarkrtls/common/helpers/routing'
import pageRoutes, { RouteKeys } from 'routes'
import {
  BarChartOutlined,
  HomeOutlined,
  LayersOutlined,
  MemoryOutlined,
  PeopleOutlined,
  SettingsOutlined,
} from '@mui/icons-material'
import { PermissionsEnum } from '@midmarkrtls/common/models'
import { ReactComponent as LayersOutlinedAdmin } from './assets/layers_admin_24px.svg'
import icons from '@midmarkrtls/common/models/icons'
import CustomSVGIcon from '@midmarkrtls/common/components/Icons/CustomSVGIcon'
import { CommonRouteKeys } from '@midmarkrtls/common/constants'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'

export const pageGroups: PageGroup[] = [
  {
    key: CommonRouteKeys.DashboardPage.key,
    name: 'Dashboard',
    icon: <HomeOutlined />,
    link: '',
    pages: [],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(CommonRouteKeys.DashboardPage.key, pageRoutes)
    },
  },
  {
    key: CommonRouteKeys.RealTimePage.key,
    name: 'Real-Time',
    link: 'realtime',
    icon: <LayersOutlined />,
    pages: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(CommonRouteKeys.RealTimePage.key, pageRoutes)
    },
  },
  {
    key: CommonRouteKeys.CloudReportingLandingPage.key,
    name: 'Analytics',
    icon: <BarChartOutlined />,
    link: 'analytics',
    permissions: [PermissionsEnum.CloudReportingTableauViewer],
    pages: [],
    get associatedRoute(): PageRoute | undefined {
      return getAssociatedRoute(
        CommonRouteKeys.CloudReportingLandingPage.key,
        pageRoutes
      )
    },
  },
  {
    key: 'administration',
    name: 'Administration',
    icon: <SettingsOutlined />,
    pages: [
      {
        key: CommonRouteKeys.AssetManagementPage.key,
        name: 'Assets',
        link: 'admin/assets',
        icon: <LayersOutlinedAdmin />,
        permissions: [
          PermissionsEnum.AssetsAdminRead,
          PermissionsEnum.AssetsAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.AssetManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.StaffManagementPage.key,
        name: 'Staff',
        link: 'admin/staff',
        icon: <CustomSVGIcon svgText={icons.staffAdminIcon} />,
        permissions: [
          PermissionsEnum.StaffAdminRead,
          PermissionsEnum.StaffAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.StaffManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.BadgePage.key,
        name: 'Badges',
        link: 'admin/badges',
        icon: <CustomSVGIcon svgText={icons.badgeIcon} />,
        permissions: [PermissionsEnum.BadgesRead, PermissionsEnum.BadgesWrite],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(CommonRouteKeys.BadgePage.key, pageRoutes)
        },
      },
      {
        key: CommonRouteKeys.UserManagementPage.key,
        name: 'Users',
        link: 'admin/users',
        icon: <PeopleOutlined />,
        permissions: [PermissionsEnum.UsersRead, PermissionsEnum.UsersWrite],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.UserManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.HardwareManagementPage.key,
        name: 'Hardware',
        link: 'admin/hardware',
        icon: <MemoryOutlined />,
        permissions: [
          PermissionsEnum.HardwareRead,
          PermissionsEnum.HardwareWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            CommonRouteKeys.HardwareManagementPage.key,
            pageRoutes
          )
        },
      },
      {
        key: RouteKeys.LocationManagementPage,
        name: 'Locations',
        link: 'admin/locations',
        icon: <BusinessOutlinedIcon />,
        permissions: [
          PermissionsEnum.LocationsRead,
          PermissionsEnum.LocationsWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(
            RouteKeys.LocationManagementPage,
            pageRoutes
          )
        },
      },
      {
        key: CommonRouteKeys.AlertManagementPage.key,
        name: 'Alerts',
        link: 'admin/alerts',
        icon: <CustomSVGIcon svgText={icons.alertAdminIcon} />,
        permissions: [
          PermissionsEnum.AlertAdminRead,
          PermissionsEnum.AlertAdminWrite,
        ],
        get associatedRoute(): PageRoute | undefined {
          return getAssociatedRoute(RouteKeys.AlertManagementPage, pageRoutes)
        },
      },
    ],
  },
]
