import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCloudReportingEmbedUrlAction } from '../actions/cloudReporting'
import { FetchingStatus } from '../models'
import {
  CloudReportingState,
  FetchCloudReportingErrorTypes,
} from '../models/cloudReporting'

export interface FetchCloudReportingEmbedUrlResult {
  status: FetchingStatus | undefined
  url: string | undefined
  errorType: FetchCloudReportingErrorTypes
  reloadEmbedUrl: () => void
}

const useFetchCloudReportingEmbedUrl = (
  embedPath: string
): FetchCloudReportingEmbedUrlResult => {
  const dispatch = useDispatch()
  const cloudReporting = useSelector(
    ({ cloudReporting }: { cloudReporting: CloudReportingState }) =>
      cloudReporting
  )

  const reloadEmbedUrl = () => {
    dispatch(getCloudReportingEmbedUrlAction.request(embedPath))
  }

  useEffect(() => {
    if (embedPath) {
      dispatch(getCloudReportingEmbedUrlAction.request(embedPath))
    }
  }, [dispatch, embedPath])

  const errorType = !cloudReporting.error
    ? FetchCloudReportingErrorTypes.None
    : cloudReporting.error.status === 403
    ? FetchCloudReportingErrorTypes.Forbidden
    : FetchCloudReportingErrorTypes.Unknown

  return {
    status: cloudReporting.status,
    url: cloudReporting.data.embedUrl,
    errorType,
    reloadEmbedUrl,
  }
}

export { useFetchCloudReportingEmbedUrl }
