import React, { ReactElement, useEffect, useState } from 'react'
import { redirect } from 'react-router-dom'
import { setTimeout } from 'timers'
import { PrimaryButton } from '../../components/Buttons'
import ErrorWithActionButton from '../../components/Common/ErrorWithActionButton'
import LoadingIndicator from '../../components/LoadingIndicator'
import { useFetchCloudReportingEmbedUrl } from '../../hooks/useFetchCloudReportingEmbedUrl'
import { FetchingStatus } from '../../models'
import { FetchCloudReportingErrorTypes } from '../../models/cloudReporting'
import { useQueryParams } from '../../hooks'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    errorContainer: {
      padding: 48,
    },
  })
)

const CloudReportingReportPage = (): ReactElement => {
  const { embedPath } = useQueryParams()
  const classes = useStyles()

  const {
    status: fetchingEmbedUrlStatus,
    url,
    errorType,
    reloadEmbedUrl,
  } = useFetchCloudReportingEmbedUrl(embedPath)
  const [primaryError, setPrimaryError] = useState('')
  const [secondaryError, setSecondaryError] = useState('')
  const [errorButtonText, setErrorButtonText] = useState('')
  const [isLoadingIframe, setIsLoadingIframe] = useState(true)

  useEffect(() => {
    if (errorType !== FetchCloudReportingErrorTypes.None) {
      if (errorType === FetchCloudReportingErrorTypes.Forbidden) {
        setErrorButtonText('RETURN TO DASHBOARD')
        setPrimaryError('Permission Denied')
        setSecondaryError(
          'You do not have permission to access this page. Please contact your administrator for assistance.'
        )
      } else {
        setErrorButtonText('RELOAD')
        setPrimaryError('We are having trouble with CareFlow Analytics.')
        setSecondaryError(
          'Please try again later or contact your administrator for assistance.'
        )
      }
    }
  }, [errorType])

  const handleErrorActionButtonClick = (): void => {
    if (errorType === FetchCloudReportingErrorTypes.Forbidden) {
      redirect('/')
    } else {
      window.location.reload()
    }
  }

  const handleResetReport = () => {
    reloadEmbedUrl()
    setIsLoadingIframe(true)
  }

  const onIframeLoaded = () => {
    // The Tableau server takes some time to finish rendering the content
    setTimeout(() => {
      setIsLoadingIframe(false)
    }, 1000)
  }

  return (
    <div className={classes.container}>
      {fetchingEmbedUrlStatus === FetchingStatus.Request && (
        <LoadingIndicator />
      )}
      {fetchingEmbedUrlStatus === FetchingStatus.Success && (
        <>
          {!isLoadingIframe && (
            <PrimaryButton
              variant='contained'
              type={'button'}
              onClick={handleResetReport}
              styleOverride={{
                root: {
                  position: 'absolute',
                  float: 'right',
                  right: '50px',
                  top: '10px',
                },
              }}
            >
              Reset Report
            </PrimaryButton>
          )}
          <iframe
            title='Analytics'
            src={url}
            onLoad={onIframeLoaded}
            style={{ flexGrow: 1, border: 'none', margin: 0, padding: 0 }}
          />
        </>
      )}
      {fetchingEmbedUrlStatus === FetchingStatus.Failure && (
        <div className={`${classes.container} ${classes.errorContainer}`}>
          <ErrorWithActionButton
            actionButtonText={errorButtonText}
            errorMessagePrimary={primaryError}
            errorMessageSecondary={secondaryError}
            onActionButtonClicked={handleErrorActionButtonClick}
          ></ErrorWithActionButton>
        </div>
      )}
    </div>
  )
}

export default CloudReportingReportPage
