import React from 'react'
import { SecondaryHeaderProvider } from '@midmarkrtls/common/components/Common/SecondaryHeader/useSecondaryHeader'
import { ProtectedContent } from '@midmarkrtls/common/components/Authentication'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material'
import MidmarkTheme from '@midmarkrtls/common/styles/MidmarkTheme'
import RootLayout from './RootLayout'
import routes from 'routes'
import store, { getPersistor } from '../store/configureStore'
import AuthConfig from '../msalConfig'
import TelemetryProvider from '../TelemetryProvider'
import { Authentication } from '@midmarkrtls/common/authentication/AuthenticationManager'
import withClearCache from '@midmarkrtls/common/components/withClearCache'
import packageJSON from '../../package.json'
import { SnackbarProvider } from '@midmarkrtls/common/components/Common/Snackbar/useSnackbar'
import { AlertActiveConfigProvider } from '@midmarkrtls/common/pages/AlertManagement'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const instrumentationKey = process.env.REACT_APP_AI_INSTRUMENTATION_KEY || ''

// initialize msal instance
const msalInstance = new PublicClientApplication(AuthConfig.msalConfig)
Authentication.setAuthConfig(AuthConfig)

const Root = () => {
  return (
    <>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={MidmarkTheme}>
          <TelemetryProvider instrumentationKey={instrumentationKey}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={getPersistor()}>
                <MsalProvider instance={msalInstance}>
                  <SecondaryHeaderProvider>
                    <SnackbarProvider>
                      <AlertActiveConfigProvider>
                        <ProtectedContent
                          loginRequest={AuthConfig.loginRequest}
                          routes={routes}
                          RootLayout={RootLayout}
                        />
                      </AlertActiveConfigProvider>
                    </SnackbarProvider>
                  </SecondaryHeaderProvider>
                </MsalProvider>
              </PersistGate>
            </Provider>
          </TelemetryProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default withClearCache(Root, packageJSON)
