import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactElement } from 'react'
import { Skeleton } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

export interface SkeletonPaperProps {
  height?: number
}

const SkeletonPaper = (props: SkeletonPaperProps): ReactElement => {
  const { height = 200 } = props
  const classes = useStyles()
  return (
    <Paper className={classes.paper} style={{ padding: 0, height }}>
      <Skeleton
        variant='rectangular'
        style={{ width: '100%', height: '100%', borderRadius: 4 }}
      ></Skeleton>
    </Paper>
  )
}

export { SkeletonPaper }
