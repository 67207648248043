import React from 'react'
import { Badge, FetchingStatus } from '../../models'
import BadgeManagementList from './BadgeManagementList'
import { PageContent } from '../Layout'
import { useSelector } from 'react-redux'
import { PageLayoutState } from '../../models/pageLayout'

interface Props<T> {
  badges: T[]
  badgeStatus: FetchingStatus | undefined
}

const BadgeAdminPage = <T extends Badge>(props: Props<T>): JSX.Element => {
  const maxHeight = useSelector(
    ({ pageLayout }: { pageLayout: PageLayoutState }) => pageLayout.maxHeight
  )

  return (
    <PageContent
      maxHeight={maxHeight}
      content={<BadgeManagementList {...props} />}
    />
  )
}

export default BadgeAdminPage
