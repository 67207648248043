import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const reactPlugin: ReactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights

/**
 * Create the App Insights Telemetry Service
 */
const createTelemetryService = () => {
  /**
   * Initialize the Application Insights class
   */
  const initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error(
        'Instrumentation key not provided in ./src/telemetry-provider.jsx'
      )
    }

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        disableFetchTracking: false,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        // If any of your or third-party servers that client communicates with cannot accept
        // Request-Id, Request-Context header and you cannot update their configuration
        // then you’ll need to put them into excluded list in correlationHeaderExcludedDomains config property
        // https://github.com/microsoft/ApplicationInsights-JS/issues/419
        correlationHeaderExcludedDomains: [
          'api.jibestream.com',
          'static.zdassets.com',
          'ekr.zdassets.com',
          process.env.REACT_APP_B2C_INSTANCE ?? '',
          process.env.REACT_APP_CURRENT_SENSOR_ROOT ?? '',
          process.env.REACT_APP_SIGNALR_CONNECTION_MANAGER ?? '',
        ],
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
      },
    })

    appInsights.loadAppInsights()
  }

  return { reactPlugin, appInsights, initialize }
}

export const ai = createTelemetryService()
export const getAppInsights = (): ApplicationInsights => appInsights
