import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import statusReducer from './status'
import uiReducer from './ui'
import commonReducersMap from '@midmarkrtls/common/reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'currentLocation', 'locationSelection'],
}

const allReducers = {
  ...commonReducersMap,
  status: statusReducer,
  ui: uiReducer,
}

const rootReducer = combineReducers(allReducers)

export type RootState = StateType<typeof rootReducer>

export default persistReducer(persistConfig, rootReducer)
