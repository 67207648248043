import React, { useCallback } from 'react'
import { Avatar, Card, CardActionArea, CardContent } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../styles'

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      alignItems: 'stretch',
      minHeight: 150,
    },
    cardContent: {
      display: 'flex',
      padding: 30,
    },
    avatar: {
      height: 90,
      width: 90,
      marginRight: 30,
      backgroundColor: colors.primary,
      fontSize: 36,
      fontWeight: 'bold',
    },
  })
)

interface Props {
  avatarText: string
  children: React.ReactNode
  onActionAreaClick: () => void
}

const AvatarCard = (props: Props): JSX.Element => {
  const { avatarText, children, onActionAreaClick } = props
  const classes = useStyles()

  const formatAvatar = useCallback(
    (tenantName: string) =>
      tenantName
        .split(' ')
        .slice(0, 2)
        .map((x) => x.charAt(0).toUpperCase())
        .join(''),
    []
  )

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={onActionAreaClick}>
        <CardContent className={classes.cardContent}>
          <Avatar className={classes.avatar}>{formatAvatar(avatarText)}</Avatar>
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export { AvatarCard }
