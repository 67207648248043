import { getType } from 'typesafe-actions'
import { UIAction } from 'actions'
import { clearStatusAction, setStatusAction } from 'actions/ui'

type StatusState = Readonly<string[]>

const statusReducer = (
  state: StatusState = [],
  action: UIAction
): StatusState => {
  switch (action.type) {
    case getType(setStatusAction):
      return [...state, action.payload]
    case getType(clearStatusAction):
      return state.slice(1)
    default:
      return state
  }
}

export default statusReducer
