import React from 'react'
import { Typography } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() =>
  createStyles({
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: { padding: 48 },
    text: {
      fontWeight: 700,
    },
  })
)

interface Props {
  text: string
}

const AvatarCardLargeTextContent = (props: Props): JSX.Element => {
  const { text } = props
  const classes = useStyles()

  return (
    <div className={classes.textContainer}>
      <Typography variant='h6' className={classes.text}>
        {text}
      </Typography>
    </div>
  )
}

export { AvatarCardLargeTextContent }
