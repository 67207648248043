import { DownForMaintenance } from '@midmarkrtls/common/components/Common/DownForMaintenance'
import { CommonRouteKeys } from '@midmarkrtls/common/constants'
import { PageRoute } from '@midmarkrtls/common/interfaces/modules'
import { PermissionsEnum } from '@midmarkrtls/common/models'
import { AlertAdminPage } from '@midmarkrtls/common/pages/AlertManagement'
import {
  AlertConfigChannelPage,
  AlertConfigConditionPage,
  AlertConfigResolutionPage,
  AlertConfigReviewPage,
  AlertConfigRoutingPage,
} from '@midmarkrtls/common/pages/AlertManagement/AlertConfig'
import { BadgeManagementPage } from '@midmarkrtls/common/pages/Badges'
import { PageNotFound } from '@midmarkrtls/common/pages/Errors'
import { RealTimePage } from '@midmarkrtls/common/pages/RealTime'
import { UserManagementPage } from '@midmarkrtls/common/pages/UserManagement'
import { UserProfilePage } from '@midmarkrtls/common/pages/UserProfile'
import {
  CloudReportingLandingPage,
  CloudReportingReportPage,
} from '@midmarkrtls/common/pages/CloudReporting'
import React from 'react'
import { LocationManagementPage } from '@midmarkrtls/common/pages/LocationManagement'
import { NotificationsPage } from '@midmarkrtls/common/pages/Notifications'

export enum RouteKeys {
  UserManagementPage = 'UserManagementPage',
  UserProfilePage = 'UserProfilePage',
  AlertManagementPage = 'AlertManagementPage',
  LocationManagementPage = 'LocationManagementPage',
  NotificationsPage = 'NotificationsPage',
}

const pageRoutes: PageRoute[] = [
  {
    key: CommonRouteKeys.DownForMaintenance.key,
    path: CommonRouteKeys.DownForMaintenance.path,
    pageComponent: DownForMaintenance,
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: CommonRouteKeys.DashboardPage.key,
    path: CommonRouteKeys.DashboardPage.path,
    pageComponent: React.lazy(() => import('./containers/DashboardPage')),
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: CommonRouteKeys.RealTimePage.key,
    path: CommonRouteKeys.RealTimePage.path,
    pageComponent: RealTimePage,
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.ListRead, PermissionsEnum.ListWrite],
  },
  {
    key: CommonRouteKeys.UserManagementPage.key,
    path: CommonRouteKeys.UserManagementPage.path,
    pageComponent: UserManagementPage,
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.UsersRead, PermissionsEnum.UsersWrite],
  },
  {
    key: CommonRouteKeys.AssetManagementPage.key,
    path: CommonRouteKeys.AssetManagementPage.path,
    pageComponent: React.lazy(() => import('./containers/AssetManagementPage')),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AssetsAdminRead,
      PermissionsEnum.AssetsAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.StaffManagementPage.key,
    path: CommonRouteKeys.StaffManagementPage.path,
    pageComponent: React.lazy(() => import('./containers/StaffManagementPage')),
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.StaffAdminRead,
      PermissionsEnum.StaffAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.HardwareManagementPage.key,
    path: CommonRouteKeys.HardwareManagementPage.path,
    pageComponent: React.lazy(
      () => import('./containers/HardwareManagementPage')
    ),
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.HardwareRead, PermissionsEnum.HardwareWrite],
  },
  {
    key: CommonRouteKeys.BadgePage.key,
    path: CommonRouteKeys.BadgePage.path,
    pageComponent: BadgeManagementPage,
    exact: false,
    subroutes: [],
    permissions: [PermissionsEnum.BadgesRead, PermissionsEnum.BadgesWrite],
  },
  {
    key: CommonRouteKeys.AlertManagementPage.key,
    path: CommonRouteKeys.AlertManagementPage.path,
    pageComponent: AlertAdminPage,
    subroutes: [],
    exact: true,
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigConditionPage.key,
    path: CommonRouteKeys.AlertConfigConditionPage.path,
    pageComponent: AlertConfigConditionPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigChannelPage.key,
    path: CommonRouteKeys.AlertConfigChannelPage.path,
    pageComponent: AlertConfigChannelPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigRoutingPage.key,
    path: CommonRouteKeys.AlertConfigRoutingPage.path,
    pageComponent: AlertConfigRoutingPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigResolutionPage.key,
    path: CommonRouteKeys.AlertConfigResolutionPage.path,
    pageComponent: AlertConfigResolutionPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    key: CommonRouteKeys.AlertConfigReviewPage.key,
    path: CommonRouteKeys.AlertConfigReviewPage.path,
    pageComponent: AlertConfigReviewPage,
    exact: true,
    subroutes: [],
    permissions: [
      PermissionsEnum.AlertAdminRead,
      PermissionsEnum.AlertAdminWrite,
    ],
  },
  {
    path: CommonRouteKeys.ErrorPage.path,
    key: CommonRouteKeys.ErrorPage.key,
    pageComponent: PageNotFound,
    exact: true,
    subroutes: [],
    permissions: [],
  },
  {
    key: RouteKeys.UserProfilePage,
    path: '/user-profile',
    pageComponent: UserProfilePage,
    exact: false,
    subroutes: [],
    permissions: [],
  },
  {
    key: CommonRouteKeys.CloudReportingLandingPage.key,
    path: CommonRouteKeys.CloudReportingLandingPage.path,
    pageComponent: CloudReportingLandingPage,
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CloudReportingTableauViewer],
  },
  {
    key: 'CloudReportingReportPage',
    path: '/analytics/report',
    pageComponent: CloudReportingReportPage,
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CloudReportingTableauViewer],
  },
  {
    key: CommonRouteKeys.CloudReportingReportPage.key,
    path: CommonRouteKeys.CloudReportingReportPage.path,
    pageComponent: CloudReportingReportPage,
    exact: true,
    subroutes: [],
    permissions: [PermissionsEnum.CloudReportingTableauViewer],
  },
  {
    key: RouteKeys.LocationManagementPage,
    path: CommonRouteKeys.LocationManagementPage.path,
    pageComponent: LocationManagementPage,
    exact: false,
    subroutes: [],
    permissions: [
      PermissionsEnum.LocationsRead,
      PermissionsEnum.LocationsWrite,
    ],
  },
  {
    key: RouteKeys.NotificationsPage,
    path: CommonRouteKeys.NotificationsPage.path,
    pageComponent: NotificationsPage,
    exact: false,
    subroutes: [],
    permissions: [],
  },
]

export default pageRoutes
