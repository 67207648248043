import React from 'react'
import BadgeAdminPage from '../../components/Badges/BadgeAdminPage'
import { PageLayout, PageToolbar } from '../../components/Layout'
import { useFetchBadges } from '../../hooks/entities/useFetchbadges'

const BadgeManagementPage: React.FC = () => {
  const { data: badges, status: badgeStatus } = useFetchBadges()

  return (
    <PageLayout>
      <PageToolbar searchStorageName={'badges'}></PageToolbar>
      <BadgeAdminPage
        badges={Object.values(badges)}
        badgeStatus={badgeStatus}
      />
    </PageLayout>
  )
}

export default BadgeManagementPage
