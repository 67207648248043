import React, { ReactElement, useEffect, useState } from 'react'
import { useFetchCloudReportingViews } from '../../hooks/useFetchCloudReportingViews'
import { FetchingStatus } from '../../models'
import ErrorWithActionButton from '../../components/Common/ErrorWithActionButton'
import AvatarCardGrid from '../../components/Grids/AvatarCardGrid'
import { PageLayout } from '../../components/Layout'
import { PageTitle } from '../../components/Layout/PageTitle'
import { useNavigate } from 'react-router'
import { buildURL } from '../../helpers'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FetchCloudReportingErrorTypes } from '../../models/cloudReporting'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      paddingLeft: 48,
      paddingRight: 48,
    },
  })
)

const CloudReportingLandingPage = (): ReactElement => {
  const { status, views, errorType } = useFetchCloudReportingViews()
  const classes = useStyles()
  const [primaryError, setPrimaryError] = useState('')
  const [secondaryError, setSecondaryError] = useState('')
  const [errorButtonText, setErrorButtonText] = useState('')
  const history = useNavigate()

  const handleClick = (id: string) => {
    const embedPath = views?.find((v) => v.id === id)?.embedPath ?? ''
    history(buildURL(`/analytics/report`, [['embedPath', embedPath]]))
  }

  const handleErrorActionButtonClick = (): void => {
    if (errorType === FetchCloudReportingErrorTypes.Forbidden) {
      history('/')
    } else {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (errorType !== FetchCloudReportingErrorTypes.None) {
      if (errorType === FetchCloudReportingErrorTypes.Forbidden) {
        setErrorButtonText('RETURN TO DASHBOARD')
        setPrimaryError('Permission Denied')
        setSecondaryError(
          'You do not have permission to access this page. Please contact your administrator for assistance.'
        )
      } else {
        setErrorButtonText('RELOAD')
        setPrimaryError('We are having trouble with CareFlow Analytics.')
        setSecondaryError(
          'Please try again later or contact your administrator for assistance.'
        )
      }
    }
  }, [errorType])

  return (
    <PageLayout>
      {status !== FetchingStatus.Failure && (
        <>
          <PageTitle title='CareFlow Analytics' />
          <div className={classes.container}>
            <AvatarCardGrid
              isLoading={status === FetchingStatus.Request}
              items={views?.map((view) => {
                return {
                  id: view.id,
                  text: view.name,
                  onClick: handleClick,
                }
              })}
            />
          </div>
        </>
      )}
      {status === FetchingStatus.Failure && (
        <div className={classes.container}>
          <ErrorWithActionButton
            actionButtonText={errorButtonText}
            errorMessagePrimary={primaryError}
            errorMessageSecondary={secondaryError}
            onActionButtonClicked={handleErrorActionButtonClick}
          ></ErrorWithActionButton>
        </div>
      )}
    </PageLayout>
  )
}

export default CloudReportingLandingPage
