import configureStore from '@midmarkrtls/common/store/configureStore'
import rootEpic from 'epics'
import rootReducer from 'reducers'
import { Store } from 'redux'
import { Persistor, persistStore } from 'redux-persist'
import setupMiddleware from './setupMiddleware'

const middlewares = setupMiddleware()
const AppStore = configureStore(rootReducer, middlewares, rootEpic)

let persistor = persistStore(AppStore)

export function getPersistor(store?: Store): Persistor {
  if (!persistor) {
    persistor = persistStore(store ?? AppStore)
  }
  return persistor
}

export default AppStore
