import { Typography } from '@mui/material'
import React from 'react'

interface Props {
  title: string
}

const PageTitle = (props: Props): JSX.Element => {
  const { title } = props

  return (
    <div style={{ padding: '28px 48px' }}>
      <Typography variant='h4' style={{ width: '100%', fontSize: 24 }}>
        {title}
      </Typography>
    </div>
  )
}

export { PageTitle }
