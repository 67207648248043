import React, { useState, useEffect } from 'react'
import { Snackbar, Typography } from '@mui/material'

interface Props {
  status: readonly string[]
  clearStatus: () => void
}

const Notifications: React.FC<Props> = (props: Props) => {
  const { status, clearStatus } = props
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (status.length) {
      setMessage(status[0])
      setOpen(true)
    }
    return () => {
      setMessage('')
    }
  }, [status])

  return message ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={2000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={() => setOpen(false)}
      message={
        <Typography color='secondary' id='message-id'>
          {message}
        </Typography>
      }
      TransitionProps={{
        onExited: clearStatus,
      }}
    />
  ) : null
}

export default Notifications
