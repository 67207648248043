import React from 'react'
import { Grid } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  AvatarCard,
  AvatarCardLargeTextContent,
} from '@midmarkrtls/common/components/Cards'
import { SkeletonPaper } from '../Common/SkeletonPaper'

interface AvatarCardGridItem {
  id: string
  text: string
  onClick: (id: string) => void
}

interface Props {
  items: AvatarCardGridItem[] | undefined
  isLoading: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      padding: 2,
    },
    card: {
      width: 405,
    },
    cardContent: {
      display: 'flex',
      padding: 30,
    },
  })
)

function AvatarCardGrid(props: Props): JSX.Element {
  const { isLoading, items = [] } = props
  const classes = useStyles()

  return (
    <>
      {isLoading && (
        <Grid container spacing={4} className={classes.gridContainer}>
          {[...Array(4).keys()].map((_, index) => (
            <Grid item key={index} className={classes.card}>
              <SkeletonPaper height={150} />
            </Grid>
          ))}
        </Grid>
      )}

      {items.length > 0 && !isLoading && (
        <Grid container spacing={4} className={classes.gridContainer}>
          {items
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((item) => (
              <Grid item key={item.id} className={classes.card}>
                <AvatarCard
                  avatarText={item.text}
                  onActionAreaClick={() => item.onClick(item.id)}
                >
                  <AvatarCardLargeTextContent text={item.text} />
                </AvatarCard>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  )
}

export default AvatarCardGrid
