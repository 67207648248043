import { createAction } from 'typesafe-actions'
// -----------------------------------------------------------------------------
// Clear the status after a notification is displayed
// -----------------------------------------------------------------------------
export const clearStatusAction = createAction('CLEAR_STATUS')()
export const setStatusAction = createAction('SET_STATUS')<string>()

export const setBadgeIdAction = createAction('SET_BADGE_ID')<string>()
export const setDeviceIdAction = createAction('SET_DEVICE_ID')<string>()
export const clearDeviceIdAction = createAction('CLEAR_DEVICE_ID')<void>()
