import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { PrimaryButton } from '../Buttons'

interface Props {
  errorMessagePrimary: string
  errorMessageSecondary: string
  actionButtonText: string | undefined
  onActionButtonClicked?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const ErrorWithActionButton = (props: Props): ReactElement => {
  const {
    errorMessagePrimary,
    errorMessageSecondary,
    actionButtonText,
    onActionButtonClicked,
  } = props

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h5'>{errorMessagePrimary}</Typography>
      <Typography>{errorMessageSecondary}</Typography>
      <PrimaryButton
        variant='contained'
        type={'button'}
        onClick={onActionButtonClicked}
      >
        <Typography noWrap style={{ fontWeight: 'bold' }}>
          {actionButtonText}
        </Typography>
      </PrimaryButton>
    </div>
  )
}

export default ErrorWithActionButton
